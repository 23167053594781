var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Distribution","show":_vm.show,"size":"xl"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)},"click-create":function($event){return _vm.create()}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div')]},proxy:true}])},[_c('CCol',{staticClass:"mt-3",attrs:{"col":"12"}},[_c('TTableAdvance',{attrs:{"items":_vm.distributions,"fields":_vm.distributionFields,"store":"order.distributions","resource":"","title":"Distributed orders","noFilter":"","removable":""},on:{"click-add":_vm.addDistribution,"row-removed":_vm.removeDistribution},scopedSlots:_vm._u([{key:"order_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.order)?_c('TLink',{attrs:{"content":item.order.id,"messageOptions":{ bold: true },"to":_vm.lodash.getReferenceLink('order', item.order.id)}}):_vm._e()],1)]}},{key:"customer_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.customer ? item.customer.name : '...',"noTranslate":"","bold":""}})],1)]}},{key:"order_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.order_item)?_c('TMessageNumber',{attrs:{"value":item.order_item.quantity}}):_vm._e()],1)]}},{key:"remaining_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.order_item)?_c('TMessageNumber',{attrs:{"value":item.order_item.remaining_distributed_quantity}}):_vm._e()],1)]}},{key:"distribution_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.quantity,"editable":""},on:{"change":_vm.onUpdateQuantity},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [(_vm.purchaseItem.remaining_distributed_quantity > 0)?_c('label',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('Remaining distributable'),
                }),expression:"{\n                  content: $t('Remaining distributable'),\n                }"}],staticClass:"small text-muted"},[_vm._v(" (+"+_vm._s(_vm.purchaseItem.remaining_distributed_quantity)+")")]):_vm._e()]},proxy:true}],null,true)})],1)]}},{key:"supply_sale_price",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price,"currency":_vm.purchaseItem.currency_id,"editable":""},on:{"change":_vm.onUpdatePrice}})],1)]}}])})],1),_c('CCol',{attrs:{"col":"12"}},[_c('TTableAdvance',{staticClass:"mt-3",attrs:{"items":_vm.orderItems,"fields":_vm.remainingOrderFields,"store":"order.distributions","resource":"","title":"Remaining orders","noFilter":"","addable":""},on:{"click-add":_vm.addDistribution},scopedSlots:_vm._u([{key:"customer_id",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.customer ? item.customer.name : '...',"noTranslate":"","bold":""}})],1)]}},{key:"supply_sale_price",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TInputMoney',{attrs:{"value":item.supply_sale_price,"currency":_vm.purchaseItem.currency_id},on:{"update:value":function($event){return _vm.$set(item, "supply_sale_price", $event)}}})],1)]}},{key:"distribution_quantity",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TInputNumber',{attrs:{"value":item.distribution_quantity},on:{"update:value":function($event){return _vm.$set(item, "distribution_quantity", $event)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }