var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.purchaseItems,"fields":_vm.itemFields,"store":"order.available_for_distribution","resource":"purchases","title":"","reloadable":""},on:{"click-reload":_vm.refresh,"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"px-1 text-center",attrs:{"col":"12"}},[_c('SButtonDistribution',{on:{"click":function($event){return _vm.clickDistribution(item.id)}}}),_c('TButtonEnter',{attrs:{"to":_vm.lodash.getReferenceLink('purchase', ("" + (item.purchase_id)))}})],1)]}},{key:"product",fn:function(ref){
var item = ref.item;
return [_c('td',[(item)?_c('SCardProductItems',{attrs:{"item":item.product,"widthAuto":"","resource":"/purchase/goods/products"}}):_vm._e()],1)]}},{key:"purchase_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.purchase_id}})],1)]}},{key:"remaining_distributed",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"bold":true,"content":item.remaining_distributed_quantity}})],1)]}},{key:"distributed_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex flex-row"},[_c('TMessageNumber',{staticClass:"px-2 font-weight-bold",attrs:{"value":item.distributed_quantity}}),_c('span',[_vm._v("/")]),_c('TMessageNumber',{staticClass:"px-2 font-weight-bold",attrs:{"value":item.quantity}})],1)])]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TTableAsForm',{attrs:{"data":item,"fields":[
            { key: 'payment_due_date', label: 'Payment due date' },
            {
              key: 'expected_delivery',
              label: 'Expected delivery',
            } ],"splitLeft":6,"addRowClasses":['pb-1']},scopedSlots:_vm._u([{key:"payment_due_date",fn:function(){return [_c('TMessageDateTime',{attrs:{"dateOnly":"","content":item.payment_due_date}})]},proxy:true},{key:"expected_delivery",fn:function(){return [_c('TMessageDateTime',{attrs:{"dateOnly":"","content":item.expected_delivery}})]},proxy:true}],null,true)})],1)]}},{key:"note",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.note,"messageOptions":{ truncate: 3 }}})],1)]}},{key:"purchase_id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.purchase_id,"placeholder":"Purchase Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "purchase_id", $event)},_vm.filterChange]}})]},proxy:true},{key:"product-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.product_id,"placeholder":"Jancode"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "product_id", $event)},_vm.filterChange]}})]},proxy:true}])}),(!_vm.lodash.isEmpty(_vm.purchase))?_c('Distribution',{attrs:{"show":_vm.showDistribution,"organization":_vm.purchase.organization_id},on:{"update:show":function($event){_vm.showDistribution=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }